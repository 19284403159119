import React from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { useModalContext } from '../../../../../shared/lib/react-context-modal'
import { IconButton } from '../../../../../components/icon-button'
import {
  InfoOrangeIcon,
  LunchAndDinnerIcon,
  MaintenanceProgramIcon,
  WeightGainIcon,
  WeightLossIcon,
} from '../../components/icons'
import {
  useGetters,
  useOrderFoodContext,
  OrderFoodOnlineType,
  OrderNumberOfMealsType,
  OrderProgramType,
} from '../../model'
import { OrderFoodProgramModal } from './order-food-program-modal'
import styles from './order-food-program.module.css'
import { OrderNumberOfMeals } from '../../model/order-food-online-type'

export const useHandlersOrderFoodProgram = () => {
  const [, setState] = useOrderFoodContext()

  const weightLossProgram = () => {
    setState({
      [OrderFoodOnlineType.Program]: OrderProgramType.WeightLossProgram,
      [OrderFoodOnlineType.NumberOfMeals]: OrderNumberOfMeals.Three,
    })
  }

  const recruitmentProgram = () => {
    setState({
      [OrderFoodOnlineType.Program]: OrderProgramType.RecruitmentProgram,
      [OrderFoodOnlineType.NumberOfMeals]: OrderNumberOfMeals.Five,
    })
  }

  const maintenanceProgram = () => {
    setState({
      [OrderFoodOnlineType.Program]: OrderProgramType.MaintenanceProgram,
      [OrderFoodOnlineType.NumberOfMeals]: OrderNumberOfMeals.Five,
    })
  }

  const lunchAndDinnerProgram = () => {
    setState({
      [OrderFoodOnlineType.Program]: OrderProgramType.LunchAndDinnerProgram,
      [OrderFoodOnlineType.NumberOfMeals]: OrderNumberOfMeals.Two,
    })
  }

  return {
    weightLossProgram,
    recruitmentProgram,
    maintenanceProgram,
    lunchAndDinnerProgram,
  }
}

export const OrderFoodProgram = ({ setIsChanged }) => {
  const { t } = useTranslation()
  const { showModal } = useModalContext()
  const handlers = useHandlersOrderFoodProgram()
  const getters = useGetters()

  const handleOrderFoodProgramModal = () => {
    showModal(OrderFoodProgramModal)
  };

  const handleWeightLossProgramClick = () => {
    if (!getters.isWeightLossProgram) {
      handlers.weightLossProgram()
      setIsChanged(true)
    }
    const userData = localStorage.getItem('order-food-online')
    const data = JSON.parse(userData)

    const program = 'WEIGHT_LOSS_PROGRAM'

    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        event: 'online_order_program',
        pageURL: `${window.location.pathname}/program=${program}`,
        program
      })

  };

  const handleRecruitmentProgramClick = () => {
    if (!getters.isRecruitmentProgram) {
      handlers.recruitmentProgram()
      setIsChanged(true)
    }
    const userData = localStorage.getItem('order-food-online')
    const data = JSON.parse(userData)

    const program = 'RECRUITMENT_PROGRAM'

    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        event: `online_order_program`,
        pageURL: `${window.location.pathname}/program=${program}`,
        program
      })

  };

  const handleMaintenanceProgramClick = () => {
    if (!getters.isMaintenanceProgram) {
      handlers.maintenanceProgram()
      setIsChanged(true)
    }
    const userData = localStorage.getItem('order-food-online')
    const data = JSON.parse(userData)

    const program = 'MAINTENANCE_PROGRAM'

    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        event: 'online_order_program',
        pageURL: `${window.location.pathname}/program=${program}`,
        program
      })

  };

  const handleLunchAndDinnerProgramClick = () => {
    if (!getters.isLunchAndDinnerProgram) {
      handlers.lunchAndDinnerProgram()
      setIsChanged(true)
    }
    const userData = localStorage.getItem('order-food-online')
    const data = JSON.parse(userData)

    const program = 'LUNCH_AND_DINNER'
    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        event: 'online_order_program',
        pageURL: `${window.location.pathname}/program=${program}`,
        program
      })

  };

  return (
    <div className={styles.programBlock}>
      <h3>
        <span>{t('home.orderFoodOnline.programTitle')}</span>
        <IconButton onClick={handleOrderFoodProgramModal}>
          <InfoOrangeIcon />
        </IconButton>
      </h3>
      <div className={styles.selectBlockContainer}>
        <div className={styles.buttonGroup}>
          <div className={styles.buttonBlock}>
            <IconButton fullWidth onClick={handleWeightLossProgramClick}>
              <ButtonProgram1>
                {t('home.orderFoodOnline.weightLossProgram')}
              </ButtonProgram1>
            </IconButton>
          </div>
          <div className={styles.buttonBlock}>
            <IconButton fullWidth onClick={handleRecruitmentProgramClick}>
              <ButtonProgram2>
                {t('home.orderFoodOnline.weightGainProgram')}
              </ButtonProgram2>
            </IconButton>
          </div>
          <div className={styles.buttonBlock}>
            <IconButton fullWidth onClick={handleMaintenanceProgramClick}>
              <ButtonProgram3>
                {t('home.orderFoodOnline.maintenanceProgram')}
              </ButtonProgram3>
            </IconButton>
          </div>
          <div className={styles.buttonBlock}>
            <IconButton fullWidth onClick={handleLunchAndDinnerProgramClick}>
              <ButtonProgram4>
                {t('home.orderFoodOnline.lunchAndDinnerProgram')}
              </ButtonProgram4>
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};

const ButtonProgram1 = props => {
  const { children } = props
  const getters = useGetters()

  return (
    <div
      className={cx(styles.button, {
        [styles.active]: getters.isOrderFoodProgramWeightLoss,
      })}
    >
      <WeightLossIcon />
      <p>{children}</p>
    </div>
  )
}

const ButtonProgram2 = props => {
  const { children } = props
  const getters = useGetters()

  return (
    <div
      className={cx(styles.button, {
        [styles.active]: getters.isOrderFoodProgramWeightGain,
      })}
    >
      <WeightGainIcon />
      <p>{children}</p>
    </div>
  )
}

const ButtonProgram3 = props => {
  const { children } = props
  const getters = useGetters()

  return (
    <div
      className={cx(styles.button, {
        [styles.active]: getters.isOrderFoodProgramMaintenance,
      })}
    >
      <MaintenanceProgramIcon />
      <p>{children}</p>
    </div>
  )
}

const ButtonProgram4 = props => {
  const { children } = props
  const getters = useGetters()

  return (
    <div
      className={cx(styles.button, {
        [styles.active]: getters.isOrderFoodProgramLunchAndDinner,
      })}
    >
      <LunchAndDinnerIcon />
      <p>{children}</p>
    </div>
  )
}
