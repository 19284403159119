import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { Input as InputView } from '../form'

export const Input = ({ name, placeholder, error, ...props }) => {
  const { register, control } = useFormContext()
  const fieldValues = register(name, props)

  return (
    <Controller
      name={fieldValues.name}
      defaultValue=""
      render={({ field }) => (
        <InputView {...field} placeholder={placeholder} error={error} name={name} />
      )}
      control={control}
    />
  )
}
