import React, { useState } from 'react'

import styles from './durationOfTheProgram.module.css'

import { CheckSelectorCalculator } from '../checkSelectorCalculator'

export const DurationOfTheProgram = ({
  menu2xDisabled,
  menu3xDisabled,
  menu4xDisabled,
  setMenu,
  setOsob,
  program,
  setProgram,
}) => {
  const [isChanged, setIsChanged] = useState(false)

  return (
    <div isWide={true}>
      <div className={styles.durationOfTheProgram}>
        <CheckSelectorCalculator
          menuOfDuration={program}
          menu2xDisabled={menu2xDisabled}
          menu3xDisabled={menu3xDisabled}
          menu4xDisabled={menu4xDisabled}
          setProgram={setProgram}
          program={program}
          setIsChanged={setIsChanged}
          setMenu={setMenu}
          setOsob={setOsob}
        />
      </div>
    </div>
  )
}
