import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './checkSelectorCalculator.module.css'
import { Dropdown } from '../../features/pages/order-food-online/components/dropdown'
import { DropdownOption } from '../../features/pages/order-food-online/components/dropdown-option'
import {
  useGetters,
  useSetters,
} from '../../features/pages/order-food-online/model'
import { DropdownButton } from '../../features/pages/order-food-online/components/dropdown-button'
import { SelectTheNumberOfPeople } from '../select-the-number-of-people'
import check from './check.png'
import cx from 'classnames'

export const CheckSelectorCalculator = ({
  menu3xDisabled,
  menu4xDisabled,
  menu2xDisabled,
  menu5xDisabled,
  setIsChanged,
  setMenu,
  setOsob,
  program,
  setProgram,
}) => {
  const { t } = useTranslation()
  const isEn = document.location.pathname.includes('/en')
  const getters = useGetters()
  const setters = useSetters()
  const meals = getters.getOrderFoodNumberOfMeals
  const kcalVariants = getters.getOrderNumberOfKcalVariants
  const [opened, setOpened] = useState(false)
  const [openedKcal, setOpenedKcal] = useState(false)

  const isMenuItemDisabled = item => {
    switch (item?.value) {
      case '2 jídla':
        return menu2xDisabled
      case '3 jídla':
        return menu3xDisabled
      case '4 jídla':
        return menu4xDisabled
      default:
        return false
    }
  }
  const { menu } = useMenuOptionsFactory()

  const handleProgramJidel = jidel => {
    const meal_count = jidel
    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        event: 'calculator_meal_count',
        pageURL: `${window.location.pathname}/meal_count=${meal_count}`,
        meal_count,
      })
  }
  const handleOrderFoodNumberOfMealsChange = item => {
    const selectedMealId = Number(getters.getOrderFoodNumberOfMeals)
    const newMealId = Number(item?.id)
    handleProgramJidel(item.id)
    if (selectedMealId !== newMealId) {
      setters.setOrderFoodCountOfMeals(newMealId)
      setMenu(newMealId + 'chodové menu')
      setOpened(false)
      setIsChanged(true)
    }
  }
  const numberOfMeals = getters.getOrderFoodNumberOfMeals
  const programTypes = ['2 týdny', 'Měsíc', 'Dva měsíce']
  const handleKcal = value => {
    setProgram(value)
    setOpenedKcal(false)
    setIsChanged(true)
    const program_length = value

    setters.setCalculationDuration(program_length)

    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        event: 'calculator_program_length',
        pageURL: `${window.location.pathname}/program_length=${program_length}`,
        program_length,
      })
  }

  const isActiveMeal = id => numberOfMeals === id
  const isActiveDuration = duration => duration === getters.getCalcDuration

  const isMobile = window.innerWidth < 1024

  const filtredMenu = menu.filter(item => !(item?.disabled || isMenuItemDisabled(item)))

  return (
    <div className={styles.selectBlockContainerWrapper}>
      <div className={styles.dropdownBox}>
        <div className={styles.durationOfTheBox}>
          <h3>{t('say_goodbye_to_unhealthy_eating.numberOfMealsTitle')}</h3>
          <p>{t('say_goodbye_to_unhealthy_eating.numberOfMealsText')}</p>
        </div>
        <div className={styles.selectorsWrapper}>
          <div className={styles.dropdownBlockCalculator}>
            <p className={styles.title}>
              {t('home.orderFoodOnline.numberOfMeals')}
            </p>

            <div className={styles.dropdown}>
              <Dropdown
                fullWidth
                isCalc
                menu={filtredMenu.map(item => 
                   (
                    
                      <DropdownOption
                        className={styles.disabled}
                        disabled={item?.disabled || isMenuItemDisabled(item)}
                        value={item?.value}
                        onClick={() => handleOrderFoodNumberOfMealsChange(item)}
                      >
                        <div
                          className={cx(styles.jidelBtn, {
                            [styles.activeMeal]: isActiveMeal(item.id),
                          })}
                        >
                          {item?.label}{' '}
                          {isActiveMeal(item.id) && (
                            <span>
                              <img src={check} />
                            </span>
                          )}
                        </div>
                      </DropdownOption>
                    
                  )
                )}
              >
                <DropdownButton className={`button ${opened && 'opened'}`}>
                  <div className={styles.mealText}>
                    {menuFormat(getters.getOrderFoodNumberOfMeals)}
                  </div>
                </DropdownButton>
              </Dropdown>
            </div>
          </div>

          <div className={styles.dropdownBlockCalculator}>
            <p className={styles.title}>
              {t('home.orderFoodOnline.programDuration')}
            </p>
            <div className={styles.dropdown}>
              <Dropdown
                fullWidth
                menu={programTypes.map((item, i) => (
                  <DropdownOption
                    // disabled={menuKkal.length <= 1}
                    onClick={() => handleKcal(item)}
                    value={item}
                    key={i}
                  >
                    <div
                      className={cx(styles.durationbtn, {
                        [styles.activeDuration]: isActiveDuration(item),
                      })}
                    >
                      <span className={styles.durationItem}>
                        {t(`say_goodbye_to_unhealthy_eating.time.${item}`)}
                        {isActiveDuration(item) && (
                          <span>
                            <img src={check} />
                          </span>
                        )}
                      </span>
                    </div>
                  </DropdownOption>
                ))}
              >
                <DropdownButton className={`button ${openedKcal && 'opened'}`}>
                  <div className={styles.programm}>
                    {' '}
                    {t(`say_goodbye_to_unhealthy_eating.time.${program}`)}
                  </div>
                </DropdownButton>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`${styles.dropdownBox} ${
          isEn && isMobile ? styles.enBox : ''
        }`}
      >
        <div
          className={`${styles.durationOfTheBox} ${
            isEn && !isMobile ? styles.en : ''
          }`}
        >
          <h3
            className={`${styles.durationOfTheBoxTitle} ${
              isEn && isMobile ? styles.enTitle : ''
            }`}
          >
            {t('say_goodbye_to_unhealthy_eating.manyPeopleOrderingTitle')}
          </h3>
          <p>{t('say_goodbye_to_unhealthy_eating.manyPeopleOrderingText')}</p>
        </div>
        <SelectTheNumberOfPeople setOsob={setOsob} />
      </div>
    </div>
  )
}

const OrderNumberOfMeals = {
  Two: 2,
  Three: 3,
  Four: 4,
  Five: 5,
}

const kcalFormat = value => {
  return (value || '')?.toLocaleString().replace(',', ' ')
}

const menuFormat = value => {
  const isEn = document.location.pathname.includes('/en')
  switch (value) {
    case OrderNumberOfMeals.Two:
      return isEn ? '2-course menu' : '2 jídla'
    case OrderNumberOfMeals.Three:
      return isEn ? '3-course menu' : '3 jídla'
    case OrderNumberOfMeals.Four:
      return isEn ? '4-course menu' : '4 jídla'
    case OrderNumberOfMeals.Five:
      return isEn ? '5-course menu' : '5 jídel'
    default:
      return ''
  }
}

const useMenuOptionsFactory = () => {
  const getters = useGetters()
  const { t } = useTranslation()

  let menu = []

  if (getters.isCheckedButtonSlimming) {
    menu = getOrderFoodProgramWeightLossOptions(t)
  }

  if (getters.isCheckedButtonPickling) {
    menu = getOrderFoodProgramRecruitmentOptions(t)
  }

  if (getters.isCheckedButtonMaintenance) {
    menu = getOrderFoodProgramMaintenanceOptions(t)
  }

  if (getters.isCheckedButtonBasic) {
    menu = getOrderFoodProgramLunchAndDinnerOptions(t)
  }

  return {
    menu,
  }
}

const getOrderFoodProgramWeightLossOptions = t => {
  return [
    {
      id: 5,
      value: '5 jídel',
      label: t('say_goodbye_to_unhealthy_eating.calcFormMenuOption4'),
      disabled: false,
    },
    {
      id: 4,
      value: '4 jídla',
      label: t('say_goodbye_to_unhealthy_eating.calcFormMenuOption3'),
      disabled: false,
    },
    {
      id: 3,
      value: '3 jídla',
      label: t('say_goodbye_to_unhealthy_eating.calcFormMenuOption2'),
      disabled: false,
    },
    {
      id: 2,
      value: '2 jídla',
      label: t('say_goodbye_to_unhealthy_eating.calcFormMenuOption1'),
      disabled: false,
      active: true,
    },
  ]
}

const getOrderFoodProgramRecruitmentOptions = t => {
  return [
    {
      id: 5,
      value: '5 jídel',
      label: t('say_goodbye_to_unhealthy_eating.calcFormMenuOption4'),
      disabled: false,
    },
    {
      id: 4,
      value: '4 jídla',
      label: t('say_goodbye_to_unhealthy_eating.calcFormMenuOption3'),
      disabled: false,
    },
    {
      id: 3,
      value: '3 jídla',
      label: t('say_goodbye_to_unhealthy_eating.calcFormMenuOption2'),
      disabled: false,
      active: true,
    },
    {
      id: 2,
      value: '2 jídla',
      label: t('say_goodbye_to_unhealthy_eating.calcFormMenuOption1'),
      disabled: false,
    },
  ]
}

const getOrderFoodProgramMaintenanceOptions = t => {
  return [
    {
      id: 5,
      value: '5 jídel',
      label: t('say_goodbye_to_unhealthy_eating.calcFormMenuOption4'),
      disabled: false,
    },
    {
      id: 4,
      value: '4 jídla',
      label: t('say_goodbye_to_unhealthy_eating.calcFormMenuOption3'),
      disabled: false,
    },
    {
      id: 3,
      value: '3 jídla',
      label: t('say_goodbye_to_unhealthy_eating.calcFormMenuOption2'),
      disabled: false,
    },
    {
      id: 2,
      value: '2 jídla',
      label: t('say_goodbye_to_unhealthy_eating.calcFormMenuOption1'),
      disabled: false,
      active: true,
    },
  ]
}

const getOrderFoodProgramLunchAndDinnerOptions = t => {
  return [
    {
      id: 2,
      value: '2 jídla',
      label: t('say_goodbye_to_unhealthy_eating.calcFormMenuOption1'),
      disabled: false,
      active: true,
    },
    {
      id: 3,
      value: '3 jídla',
      label: t('say_goodbye_to_unhealthy_eating.calcFormMenuOption2'),
      disabled: false,
    },
    {
      id: 4,
      value: '4 jídla',
      label: t('say_goodbye_to_unhealthy_eating.calcFormMenuOption3'),
      disabled: false,
    },
    {
      id: 5,
      value: '5 jídel',
      label: t('say_goodbye_to_unhealthy_eating.calcFormMenuOption4'),
      disabled: false,
    },
  ]
}

const getOrderKkalOptions1 = t => {
  return [
    {
      id: 1,
      value: '1000 Kkal',
      label: t('say_goodbye_to_unhealthy_eating.from1000Kcal'),
      disabled: false,
    },
    {
      id: 2,
      value: '1600 Kkal',
      label: t('say_goodbye_to_unhealthy_eating.from1600Kcal'),
      disabled: false,
    },
  ]
}

const getOrderKkalOptions2 = t => {
  return [
    {
      id: 1,
      value: '1000 Kkal',
      label: t('say_goodbye_to_unhealthy_eating.from1000Kcal'),
      disabled: false,
    },
    {
      id: 2,
      value: '1600 Kkal',
      label: t('say_goodbye_to_unhealthy_eating.from1600Kcal'),
      disabled: false,
    },
  ]
}

const useMenuOptionsFactoryKkal = () => {
  const getters = useGetters()
  const { t } = useTranslation()

  let menuKkal = []

  if (getters.isOrderFoodProgramWeightLoss) {
    menuKkal = getOrderKkalOptions1(t)
  }

  if (getters.isOrderFoodProgramWeightGain) {
    menuKkal = getOrderKkalOptions2(t)
  }

  return {
    menuKkal,
  }
}
