import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import cx from 'classnames'

import { useGetters } from '../../model'
import { Button2 } from '../../../../../components/button2'
import styles from './order-food-contact-information-price.module.css'

export const OrderFoodContactInformationPrice = props => {
  const { t } = useTranslation()
  const getters = useGetters()

  return (
    <div className={styles.priceContent}>
      <div>
        <div className={styles.row}>
          <span className={styles.label}>
            {t('home.orderFoodOnline.pricePerDay')}:
          </span>
          <span className={styles.value}>
            {numberFormat(getters.getOrderFoodPrice)} {t('czhKrona')}
          </span>
        </div>
        <div className={styles.row}>
          <span className={styles.label}>
            <Trans i18nKey="home.orderFoodOnline.totalPrice2-1">
              Celkem <span>(vč. DPH)</span>:
            </Trans>
          </span>
          <span className={cx(styles.value, styles.green)}>
            {numberFormat(getters.getOrderFoodPriceDiscountByDays)}{' '}
            {t('czhKrona')}
          </span>
        </div>
      </div>
      <div className={styles.submit}>
        <Button2
          colorDisabled="grey"
          color="secondary"
          buttonType="submit"
          fullWidth
        >
          {t('home.orderFoodOnline.step2.btnText')}
        </Button2>
      </div>
    </div>
  )
}

const numberFormat = value => {
  if (!value) return value
  return (value || '')?.toLocaleString().replace(',', ' ')
}
