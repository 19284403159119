import React from 'react'
import styles from './dropdown-button.module.css'

export const DropdownButton = props => {
  const { children } = props
  return (
    <button {...props} type="button" className={styles.button}>
      {children}
    </button>
  )
}
