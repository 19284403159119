import React, { useState, cloneElement } from 'react'
import cx from 'classnames'
import styles from './dropdown.module.css'

const useOutsideClick = callback => {
  const ref = React.useRef()

  React.useEffect(() => {
    const handleClick = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }

    document.addEventListener('click', handleClick, true)

    return () => {
      document.removeEventListener('click', handleClick, true)
    }
  }, [ref])

  return ref
}

export const Dropdown = ({ menu, fullWidth, children, isCalc }) => {
  const [open, setOpen] = useState(false)

  const handleClickOutside = () => {
    setOpen(false)
  }

  const ref = useOutsideClick(handleClickOutside)

  const handleOpen = event => {
    event.stopPropagation()
    setOpen(!open)
  }

  const handleClick = menuItem => {
    menuItem.props.onClick()
    setOpen(false)
  }

  return (
    <div
      className={cx(styles.dropdown, {
        [styles.fullWidth]: fullWidth,
      })}
    >
      <Title onClick={handleOpen}>{children}</Title>
      {open ? (
        <>
          <div ref={ref} className={styles.menu}>
            <Title onClick={handleOpen}>{children}</Title>
            <ul className={styles.menuBlock}>
              {menu.map((menuItem, index) => (
                <DropdownOption
                  key={index}
                  isCalc={isCalc}
                  menuItem={menuItem}
                  onClick={handleClick}
                />
              ))}
            </ul>
          </div>
        </>
      ) : null}
    </div>
  )
}

const Title = props => {
  const { onClick, children } = props

  return cloneElement(children, {
    onClick,
  })
}

const DropdownOption = props => {
  const { menuItem, onClick, isCalc } = props

  return (
    <li 
    className={cx(styles.menuItem, {
      [styles.optionCalc]: isCalc
    })}
    >
      {cloneElement(menuItem, {
        onClick: () => onClick(menuItem),
      })}
    </li>
  )
}
