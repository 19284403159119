import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useLocalStorage } from '../../../../../shared/lib/local-storage'
import { clientApi } from '../../../../../shared/api'
import { PromoCodeInput } from '../../../../form/promo-code-input'
import {
  PhoneErrorMessage,
  Terms2ErrorRequiredMessage,
  TermsErrorRequiredMessage,
} from '../../../../../components/forms/forms-error-messages'
import { Button2 } from '../../../../../components/button2'
import { Form, InfoText, Label, FieldGroup } from '../../components/form'
import { Input, PhoneInput, Checkbox } from '../../components/hooks'
import { ModalFormTemplate } from '../../components/templates/modal-form-template'
import {
  OrderStorageType,
  useGetters,
  useOrderFoodContext,
  useSetters,
} from '../../model'
import { OrderFoodOnlineSidebarCalculationBlock } from './order-food-online-sidebar-calculation-block'
import {
  Divider,
  OrderFoodOnlineSidebarCalculationKcalItem,
  OrderFoodOnlineSidebarCalculationPriceItem,
  OrderFoodOnlineSidebarCalculationTransportPerDayItem,
  OrderFoodOnlineSidebarCalculationTransportTotalItem,
  OrderFoodOnlineSidebarCalculationTotalBlockItem,
  OrderFoodOnlineSidebarCalculationPromoCodeItem,
} from './order-food-online-sidebar-calculation-item'
import styles from './new-order-food-program-modal.modal.css'
import { AffiliateContext } from '../../../../../contexts/AffiliateContext'
import { OrderFoodOnlineType } from '../../model/order-food-online-type'
import GenderBlock from "../../../../../components/genderBlock/genderBlock";
import cx from "classnames";
import { MaskedPhoneInput } from '../../../../../components/forms/phone'
import { OrderFoodContactInformationPrice } from '../../pages/order-food-contact-information/order-food-contact-information-price'


export const OrderFoodOnlineSidebarCalculationKcalItemSipping = ({ size = 'lg' }) => {
  const { t } = useTranslation()

  const getters = useGetters()

  return (
    <p className={styles.kcal}>
      {t('say_goodbye_to_unhealthy_eating.shippingPerDay')}: {/* Cena za celé období:{' '} */}
      <span
        className={cx({
          [styles.itemSizeSmall]: size === 'sm',
          [styles.itemSizeLarge]: size === 'lg',
        })}
      >
        {t('say_goodbye_to_unhealthy_eating.freeOfCharge')}
      </span>
    </p>
  )
}

export const OrderFoodOnlineSidebarCalculationKcalItemTransport = ({ size = 'lg' }) => {
  const { t } = useTranslation()

  const getters = useGetters()

  return (
    <p className={styles.kcal}>
      {t('say_goodbye_to_unhealthy_eating.transportInTotal')}: {/* Cena za celé období:{' '} */}
      <span
        className={cx({
          [styles.itemSizeSmall]: size === 'sm',
          [styles.itemSizeLarge]: size === 'lg',
        })}
      >
        {t('say_goodbye_to_unhealthy_eating.freeOfCharge')}
      </span>
    </p>
  )
}

export const OrderFoodOnlineSidebarDPHPriceItemPromo = ({ size = 'lg' }) => {
  const { t } = useTranslation()

  const getters = useGetters()

  return (
    <p className={styles.price}>
      {t('say_goodbye_to_unhealthy_eating.discountPromoCode')}{' '}
      <span
        className={cx({
          [styles.itemSizeSmall]: size === 'sm',
          [styles.itemSizeLarge]: size === 'lg',
        })}
      >
        <div style={{ color: "#44C789" }}>
          -{numberFormat(getters.getOrderFoodPriceByPromo)} {t('czhKrona')}
        </div>
      </span>
    </p>
  )
}

const OrderFoodCallMeModalView = props => {
  const isMobile = window.innerWidth < 1024

  const { form, onSubmit } = props

  const { t } = useTranslation()

  const getters = useGetters()
  const setters = useSetters()

  const [, setState] = useOrderFoodContext()

  const handlePromo = data => {
    setState({
      [OrderFoodOnlineType.PromoCode]: data.value,
      [OrderFoodOnlineType.PriceDiscount]: data.totalPrice,
      [OrderFoodOnlineType.Discount]: data.discount,
    })
  }



  return (
    <ModalFormTemplate
      {...props}
      title={<h2>{t('home.orderFoodOnline.callMe')}</h2>}
    >
      <div className={styles.block}>
        <div
          className={styles.grid}
          style={{ paddingBottom: isMobile ? 0 : '24px', display: 'flex', flexDirection: 'row' }}
        >
          {/* {isMobile ? <OrderFoodOnlineSidebarProgramBlock /> : ''} */}
          <div className={styles.column}>
            <Form onSubmit={onSubmit}>
              <FieldGroup>
                <Label required>{t('forms.onlineOrderFormNamesLabel')}</Label>
                <Input
                  placeholder={t('forms.onlineOrderFormNamesLabel')}
                  name="name"
                  error={form.errors?.name?.message}
                />
              </FieldGroup>

              <FieldGroup>
                {/* <Label required>Telefon</Label>
                <PhoneInput
                  placeholder="Telefon"
                  name="phone"
                  value="+420"
                  error={form.errors?.phone?.message}
                /> */}
                <MaskedPhoneInput
                  // innerRef={phoneRef}
                  // isWhite={true}
                  name="phone"
                  value={'+420'}
                  error={form.errors?.phone?.message}
                  onChange={value => {
                    console.log('change phone', value)
                    // setFieldValue('phone', value)
                  }}
                  placeholder={"Telefon"}
                />
              </FieldGroup>

              <FieldGroup>
                <Label>{t('forms.onlineOrderFormPromoCodeLabel2')}</Label>
                <PromoCodeInput
                  placeholder="Promokód"
                  totalPrice={getters.getOrderFoodPriceByDays}
                  onChange={handlePromo}
                  isPromoApplyed={!!getters.getOrderFoodPromoCode}
                />
              </FieldGroup>

              {isMobile ? (
                <OrderFoodOnlineSidebarCalculationBlock>
                  <OrderFoodOnlineSidebarCalculationPriceItem size="sm" />
                  <Divider />
                  <OrderFoodOnlineSidebarCalculationKcalItem size="sm" />

                  {getters.isOrderFoodDeliveryFree ? null : (
                    <>
                      <Divider />
                      <OrderFoodOnlineSidebarCalculationTransportPerDayItem size="sm" />
                      <Divider />
                      <OrderFoodOnlineSidebarCalculationTransportTotalItem size="sm" />
                    </>
                  )}
                  <Divider />

                  <OrderFoodOnlineSidebarCalculationPromoCodeItem size="sm">
                    {getters.getOrderFoodDiscount &&
                      `-${getters.getOrderFoodDiscount}`}
                  </OrderFoodOnlineSidebarCalculationPromoCodeItem>
                  {/* <Divider /> */}
                  <OrderFoodOnlineSidebarCalculationTotalBlockItem size="lg">
                    {numberFormat(getters.getOrderFoodTotalPrice)}
                  </OrderFoodOnlineSidebarCalculationTotalBlockItem>
                </OrderFoodOnlineSidebarCalculationBlock>
              ) : (
                ''
              )}
              <FieldGroup>
                <Checkbox name="terms1">
                  {t('forms.mainFormCheckTerms1Label')}{' '}
                  <a href="/terms" target="_blank">
                    {t('forms.mainFormCheckTerms2Label')}
                  </a>
                  .
                </Checkbox>
                <Checkbox name="terms2">
                  {t('forms.mainFormCheckTerms3Label')}{' '}
                  <a href="/gdpr" target="_blank">
                    {t('forms.mainFormCheckTerms4Label')}
                  </a>
                </Checkbox>
              </FieldGroup>

              <InfoText>* {t('forms.mainFormCTAdesc')}</InfoText>

              <div style={{ paddingBottom: isMobile ? '24px' : 0 }}>
                <Button2
                  color="secondary"
                  size="sm"
                  fullWidth
                  buttonType="submit"
                  disabled={form.isSubmitting || !form.isDirty}
                  style={{ background: '#F22F2F' }}
                >
                  {t('home.orderFoodOnline.callMe')}
                </Button2>
              </div>
            </Form>
          </div>

          <div className={styles.column}>
            {/* {!isMobile ? <OrderFoodOnlineSidebarProgramBlock /> : ''} */}
            <GenderBlock />
            {!isMobile ? (
              <OrderFoodOnlineSidebarCalculationBlock>
                <OrderFoodOnlineSidebarCalculationPriceItem size="sm" />
                <Divider />
                <OrderFoodOnlineSidebarCalculationKcalItem size="sm" />
                {getters.isOrderFoodDeliveryFree ? null : (
                  <>
                    <Divider />
                    <OrderFoodOnlineSidebarCalculationTransportPerDayItem size="sm" />
                    <Divider />
                    <OrderFoodOnlineSidebarCalculationTransportTotalItem size="sm" />
                  </>
                )}
                {!!getters.getOrderFoodDiscount && (
                  <>
                    <Divider />
                    <OrderFoodOnlineSidebarCalculationPromoCodeItem size="sm">
                      {getters.getOrderFoodDiscount &&
                        `-${numberFormat(getters.getOrderFoodDiscount)}`}
                    </OrderFoodOnlineSidebarCalculationPromoCodeItem>
                  </>
                )}
                <Divider />
                <OrderFoodOnlineSidebarCalculationKcalItemSipping style={{ display: 'flex', justifyContent: 'spaceBetween' }} />
                <Divider />
                <OrderFoodOnlineSidebarCalculationKcalItemTransport style={{ display: 'flex', justifyContent: 'spaceBetween' }} />
                <Divider />
                <OrderFoodOnlineSidebarDPHPriceItemPromo style={{ display: 'flex', justifyContent: 'spaceBetween' }} />
                <Divider />
                <OrderFoodOnlineSidebarCalculationTotalBlockItem size="lg">
                  {numberFormat(getters.getOrderFoodTotalPrice)}
                </OrderFoodOnlineSidebarCalculationTotalBlockItem>
              </OrderFoodOnlineSidebarCalculationBlock>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </ModalFormTemplate>
  )
}

const initialState = () => ({
  name: undefined,
  phone: '+420',
  terms1: false,
  terms2: false,
  promo: '',
})

const schema = yup
  .object({
    name: yup.string().required(),
    phone: yup
      .string()
      .min(9, <PhoneErrorMessage />)
      .phone(),
    terms1: yup.bool().oneOf([true], <TermsErrorRequiredMessage />),
    terms2: yup.bool().oneOf([true], <Terms2ErrorRequiredMessage />),
  })
  .required()

export const NewOrderFoodCallMeModal = props => {
  const getters = useGetters()

  const [, setOrderStorage] = useLocalStorage(
    OrderStorageType.orderFoodOnline,
    {}
  )

  const affiliateContext = useContext(AffiliateContext)

  const methods = useForm({
    defaultValues: initialState(),
    resolver: yupResolver(schema),
  })

  const { affiliateId } = affiliateContext?.affiliate || {}

  const handleSubmit = async data => {
    const isEn = document.location.pathname.includes('/en')
    const testovani = localStorage.getItem("website-version")
    try {
      const req = await clientApi.application({
        ...data,
        price: getters.getOrderFoodTotalPrice,
        promo: getters.getOrderFoodPromoCode || data.promo,
        checkTerms: data.terms1,
        checkTerms2: data.terms2,
        numberOfMeals: getters.getOrderFoodNumberOfMeals,
        affiliateId,
        testovani
      })
      // redirect on TY page
      if (req) {
        const response = req

        setOrderStorage({})

        setTimeout(() => {
          window.dataLayer.push({
            event: 'ga.pageview',
            pageURL: isEn ? '/en/thank-you' : '/thank-you',
            pageType: 'Purchase',
            testovani: 'new online order form',
          })
          props?.hideModal && props.hideModal()
        }, 300)

        if (affiliateId) {
          const getParamsData = Object.entries(response)
            .map(([key, value]) => `${key}=${value || 0}`)
            .reduce(
              (previousValue, currentValue) =>
                `${previousValue}&${currentValue}`
            )
          console.log(
            'affiliate TY: meals + getParamsData',
            data.mealsPerDay,
            getParamsData
          )
          window.location.href = isEn
            ? `/en/thank-you-affiliate/?${getParamsData}&actionTrackerId=430565`
            : `/thank-you-affiliate/?${getParamsData}&actionTrackerId=430565`
        } else {
          console.log('no affiliateId')
          window.location.href = isEn
            ? '/en/order-food-online/thank-you-order-received/'
            : '/order-food-online/thank-you-order-received/'
        }
      }
    } catch (error) {
      console.log('error:', error)
    }
  }

  return (
    <FormProvider {...methods}>
      <OrderFoodCallMeModalView
        {...props}
        form={methods.formState}
        onSubmit={methods.handleSubmit(handleSubmit)}
      />
    </FormProvider>
  )
}

const numberFormat = value => {
  if (!value) return value
  return (value || '')?.toLocaleString().replace(',', ' ')
}
