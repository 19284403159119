import React, { useState } from 'react'
import { Container } from '../container'
import { useTranslation } from 'react-i18next'
import card1 from './Card1.png'
import card2 from './Card2.png'
import card3 from './Card3.png'
import card4 from './Card4.png'
import styles from './chooseAProgram.module.css'
import {
  useGetters,
  useSetters,
} from '../../features/pages/order-food-online/model'
import cx from 'classnames'
import { useHandlersOrderFoodProgram } from '../../features/pages/order-food-online/features/order-food-online/order-food-program'
import Slider from 'react-slick'

export const ChooseAProgram = ({
  menu2xDisabled,
  menu3xDisabled,
  menu4xDisabled,
  setPlan,
  plan3disabled,
  plan2disabled,
}) => {
  const { t } = useTranslation()
  const setters = useSetters()
  const getters = useGetters()
  const handlers = useHandlersOrderFoodProgram()
  const [activeButtonId, setActiveButtonId] = useState(1)

  const handleProgramDataLayer = (program) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        event: 'calculator_program',
        pageURL: `${window.location.pathname}/program=${program}`,
        program,
      })
  }
  const handleButtonClick = (id, planName, buttonType) => {
    setActiveButtonId(id)
    setPlan(planName)
    handleProgramDataLayer(planName)

    // Use a switch statement to handle different button types
    switch (buttonType) {
      case 'Basic':
        if (!getters.isCheckedButtonBasic) {
          setters.setCheckedButtonBasic()
        }
        break
      case 'Slimming':
        if (!getters.isCheckedButtonSlimming) {
          setters.setCheckedButtonSlimming()
        }
        break
      case 'Maintenance':
        if (!getters.isCheckedButtonMaintenance) {
          setters.setCheckedButtonMaintenance()
        }
        break
      case 'Pickling':
        if (!getters.isCheckedButtonPickling) {
          setters.setCheckedButtonPickling()
        }
        break
      default:
        break
    }
  }
  const handleBasicClick = () => {
    setPlan('Zhubnout')
    handleProgramDataLayer('Zhubnout')
    if (!getters.isCheckedButtonBasic) {
      setters.setCheckedButtonBasic()
    }
  }

  const handleSlimmingClick = () => {
    setPlan('Zhubnout')
    handleProgramDataLayer('Zhubnout')

    if (!getters.isCheckedButtonSlimming) {
      setters.setCheckedButtonSlimming()
    }
  }

  const handleMaintenanceClick = () => {
    setPlan('Udržovat')
    handleProgramDataLayer('Udržovat')

    if (!getters.isCheckedButtonMaintenance) {
      setters.setCheckedButtonMaintenance()
    }
  }

  const handlePicklingClick = () => {
    setPlan('Nabírat')
    handleProgramDataLayer('Nabírat')

    if (!getters.isCheckedButtonPickling) {
      setters.setCheckedButtonPickling()
    }
  }

  const settings = {
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 3,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 3,
          dots: false,
          arrows: false,
        },

      },
      {
        breakpoint: 390,
        settings: {
          slidesToShow: 3,
          dots: false,
          arrows: false,
        },

      },
    ],
  }
  const buttonSlides = [
    // {
    //   id: 1,
    //   title: t('say_goodbye_to_unhealthy_eating.basic'),
    //   kcal: t('say_goodbye_to_unhealthy_eating.from1000Kcal'),
    //   img: card4,
    //   ischecked: getters.isCheckedButtonBasic,
    //   program: 'Basic',
    //   plan: 'Zhubnout',
    // },
    {
      id: 2,
      title: t('say_goodbye_to_unhealthy_eating.slimming'),
      kcal: t('say_goodbye_to_unhealthy_eating.from1600Kcal'),
      img: card1,
      ischecked: getters.isCheckedButtonSlimming,
      program: 'Slimming',
      plan: 'Zhubnout',
    },
    {
      id: 3,
      title: t('say_goodbye_to_unhealthy_eating.maintenance'),
      kcal: t('say_goodbye_to_unhealthy_eating.from1900Kcal'),
      img: card3,
      ischecked: getters.isCheckedButtonMaintenance,
      program: 'Maintenance',
      plan: 'Udržovat',
    },
    {
      id: 4,
      title: t('say_goodbye_to_unhealthy_eating.pickling'),
      kcal: t('say_goodbye_to_unhealthy_eating.from2200Kcal'),
      img: card2,
      ischecked: getters.isCheckedButtonPickling,
      program: 'Pickling',
      plan: 'Nabírat',
    },
  ]
  const slides = buttonSlides.map((item, index) => (
    <div
      key={index}
      className={styles.sliderButtonBlock}
      onClick={() => handleButtonClick(item.id, item.plan, item.program)}
    >
      <MobileContainer isActive={item.id === activeButtonId}>
        <div className={styles.mobileProgramBox}>
          <div className={styles.mobileImgBox}>
            <img src={item.img} alt="card" />
            <p>{item.title}</p>
          </div>
          <p>{item.kcal}</p>
        </div>
      </MobileContainer>
    </div>
  ))

  return (
    <>
      <Container isWide={true} className={styles.containerProgram}>
        <div className={styles.programWrap}>
          {/* <ButtonBasic>
            <div className={styles.programBox} onClick={handleBasicClick}>
              <div className={styles.imgBox}>
                <img src={card4} alt="card4" />
                <p>{t('say_goodbye_to_unhealthy_eating.basic')}</p>
              </div>
              <p>{t('say_goodbye_to_unhealthy_eating.from1000Kcal')}</p>
            </div>
          </ButtonBasic> */}
          <ButtonSlimming>
            <div className={styles.programBox} onClick={handleSlimmingClick}>
              <div className={styles.imgBox}>
                <img src={card1} alt="card1" />
                <p>{t('say_goodbye_to_unhealthy_eating.slimming')}</p>
              </div>
              <p>{t('say_goodbye_to_unhealthy_eating.from1600Kcal')}</p>
            </div>
          </ButtonSlimming>
          <ButtonMaintenance plan2disabled={plan2disabled}>
            <div className={styles.programBox} onClick={handleMaintenanceClick}>
              <div className={styles.imgBox}>
                <img src={card3} alt="card3" />
                <p>{t('say_goodbye_to_unhealthy_eating.maintenance')}</p>
              </div>
              <p>{t('say_goodbye_to_unhealthy_eating.from1900Kcal')}</p>
            </div>
          </ButtonMaintenance>
          <ButtonPickling
            menu2xDisabled={menu2xDisabled}
            menu3xDisabled={menu3xDisabled}
            menu4xDisabled={menu4xDisabled}
            plan3disabled={plan3disabled}
            plan2disabled={plan2disabled}
          >
            <div className={styles.programBox} onClick={handlePicklingClick}>
              <div className={styles.imgBox}>
                <img src={card2} alt="card2" />
                <p className={styles.programTitleType}>
                  {t('say_goodbye_to_unhealthy_eating.pickling')}
                </p>
              </div>
              <p className={styles.KcalCount}>
                {t('say_goodbye_to_unhealthy_eating.from2200Kcal')}
              </p>
            </div>
          </ButtonPickling>
        </div>
        <div className={styles.calendarWrapSlider}>
          <Slider {...settings}>{slides}</Slider>
        </div>
      </Container>
    </>
  )
}

const MobileContainer = (props) => {
  const { children, isActive } = props

  return (
    <div
      className={cx(styles.mobileButton, {
        [styles.mobileActive]: isActive,
      })}
    >
      {isActive && <div className={styles.mobileCheckedProg}></div>}
      <p>{children}</p>
    </div>
  )
}

const ButtonBasic = (props) => {
  const { children } = props
  const getters = useGetters()

  return (
    <div
      className={cx(styles.button, {
        [styles.active]: getters.isCheckedButtonBasic,
      })}
    >
      {getters.isCheckedButtonBasic && (
        <div className={styles.checkedProg}></div>
      )}
      <p>{children}</p>
    </div>
  )
}

const ButtonSlimming = (props) => {
  const { children } = props
  const getters = useGetters()
  return (
    <div
      className={cx(styles.button, {
        [styles.active]: getters.isCheckedButtonSlimming,
      })}
    >
      {getters.isCheckedButtonSlimming && (
        <div className={styles.checkedProg}></div>
      )}
      <p>{children}</p>
    </div>
  )
}

const ButtonMaintenance = (props) => {
  const { children, plan2disabled } = props
  const getters = useGetters()

  return (
    <div
      className={cx(styles.button, {
        [styles.active]: getters.isCheckedButtonMaintenance,
        [styles.disabled]: plan2disabled,
      })}
      disabled={plan2disabled}
    >
      {getters.isCheckedButtonMaintenance && (
        <div className={styles.checkedProg}></div>
      )}

      <p>{children}</p>
    </div>
  )
}

const ButtonPickling = (props) => {
  const { children, plan3disabled } = props
  const getters = useGetters()

  return (
    <div
      className={cx(styles.button, {
        [styles.active]: getters.isCheckedButtonPickling,
        [styles.disabled]: plan3disabled,
      })}
      disabled={plan3disabled}
    >
      {getters.isCheckedButtonPickling && (
        <div className={styles.checkedProg}></div>
      )}

      <p>{children}</p>
    </div>
  )
}