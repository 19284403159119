import React from 'react'
import { useTranslation } from 'react-i18next'

import { ModalInfoTemplate } from '../../components/templates/modal-info-template'
import {
  LunchAndDinnerIcon,
  MaintenanceProgramIcon,
  WeightGainIcon,
  WeightLossIcon,
} from '../../components/icons'
import styles from './order-food-program-modal.module.css'

export const OrderFoodProgramModal = props => {
  const { t } = useTranslation()

  return (
    <ModalInfoTemplate {...props}>
      <div className={styles.block}>
        <div className={styles.item}>
          <h3>
            <WeightLossIcon />
            {t('home.orderFoodOnline.weightLossProgram')}
          </h3>
          <p>{t('home.orderFoodOnline.programModal.weightLossProgram')}</p>
        </div>
        <div className={styles.item}>
          <h3>
            <WeightGainIcon />
            {t('home.orderFoodOnline.weightGainProgram')}
          </h3>
          <p>{t('home.orderFoodOnline.programModal.weightGainProgram')}</p>
        </div>
        <div className={styles.item}>
          <h3>
            <MaintenanceProgramIcon />
            {t('home.orderFoodOnline.maintenanceProgram')}
          </h3>
          <p>{t('home.orderFoodOnline.programModal.maintenanceProgram')}</p>
        </div>
        <div className={styles.item}>
          <h3>
            <LunchAndDinnerIcon />
            {t('home.orderFoodOnline.lunchAndDinnerProgram')}
          </h3>
          <p>{t('home.orderFoodOnline.programModal.lunchAndDinnerProgram')}</p>
        </div>
      </div>
    </ModalInfoTemplate>
  )
}
