import cx from 'classnames'
import React, { useState, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useHomepageTabsContext } from '../../contexts/HomepageTabsContext'
import { useSmoothScroll } from '../../hooks/useSmoothScroll'
import { Button2 } from '../button2'
import { Container } from '../container'
import styles from './hero3-red.module.css'
import mainFormStyles from "../forms/main-form2.module.css";
import { useModalContext } from "../../shared/lib/react-context-modal";

import { OrderFoodCallMeModal } from '../../features/pages/order-food-online/features/order-food-online/order-food-call-me-modal'
import { Price } from '../price'

export const Hero3Red = ({ oldPrice, price, plan }) => {
  const scroll = useSmoothScroll()
  const { t } = useTranslation()

  const isEn = document.location.pathname.includes('/en')

  const calculateBtn = () => {

    const userData = localStorage.getItem('order-food-contact-information');
    const data = JSON.parse(userData);

    const name = data?.name;
    const phone = data?.phone;
    const email = data?.email;
    const zip = data?.deliveryAddress?.components?.postcode ? data?.deliveryAddress?.components?.postcode : '';
    const city = data?.city?.label ? data?.city?.label : '';
    const state = 'CZ';

    let firstName = '';
    let lastName = '';

    if (name) {
      const nameParts = name.split(' ');
      firstName = nameParts[0];
      lastName = nameParts.slice(1).join(' ');
    }

    const userInfo = {
      firstName,
      lastName,
      phone,
      email,
      zip,
      city,
      state
    };
    window.dataLayer = window.dataLayer || []

    setTimeout(() => {
      window.dataLayer.push({
        event: 'click_hp_calculate_price',
        pageURL: window.location.pathname,
        pageType: 'Purchase',
        userInfo,
      });

    }, 300);
  };

  const onlineBtn = () => {

    const userData = localStorage.getItem('order-food-contact-information');
    const data = JSON.parse(userData);

    const name = data?.name;
    const phone = data?.phone;
    const email = data?.email;
    const zip = data?.deliveryAddress?.components?.postcode ? data?.deliveryAddress?.components?.postcode : '';
    const city = data?.city?.label ? data?.city?.label : '';
    const state = 'CZ';

    let firstName = '';
    let lastName = '';
    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        event: `click_cta_online_order_calc_cta`,
        pageURL: window.location.pathname,
      })
    if (name) {
      const nameParts = name.split(' ');
      firstName = nameParts[0];
      lastName = nameParts.slice(1).join(' ');
    }

    const userInfo = {
      firstName,
      lastName,
      phone,
      email,
      zip,
      city,
      state
    };
    setTimeout(() => {
      window.dataLayer.push({
        event: 'click_hp_order_online',
        pageURL: window.location.pathname,
        pageType: 'Purchase',
        user_data: userInfo,
      });

    window.location.href = isEn ? '/en/order-food-online' : '/order-food-online';

    }, 300);
  };

  const { showModal } = useModalContext()

  const handleCallMe = () => {
    localStorage.setItem('formName', "say-goobay-unhealthy-Eating")

    showModal(OrderFoodCallMeModal)
  }

  return (
    <>
      <Container isWide={true}>
        <div
          className={cx(styles.buttons, styles.buttonWrapperMobile)}
          id="sticky-buttons"
          onClick={() => calculateBtn()}
        >
          <div className={styles.priceBoxCalculator}>
            {oldPrice && <span className={styles.disountRed}> {Math.ceil(((oldPrice - Number(price)) / oldPrice) * 100) + '%'}</span>}
            <div className={styles.priceContain}>
              <Price price={price} oldPrice={oldPrice} plan={plan} />
            </div>
            <div className={styles.callMe}>
              <Button2
                id={styles.firstBtn}
                color="secondary"
                variant="outline"
                size={'sm'}
                buttonType="submit"
                disabled={false}
                className={mainFormStyles.submitButton}
                onClick={handleCallMe}
              >
                {t('forms.mainFormCTA')}
              </Button2>
              <>

                  <Button2
                    id={styles.secondBtn}
                    color="secondary"
                    size={'sm'}
                    disabled={false}
                    className={mainFormStyles.submitButton}
                    onClick={onlineBtn}
                  >
                    {t('forms.mainFormCTA2')}{' '}
                  </Button2>
              </>

              <input type="hidden" name="price" />
            </div>
          </div>

        </div>
      </Container>

    </>

  )
}
