import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import styles from './select-the-number-of-people.module.css';
import { IconButton } from "../icon-button";
import { ButtonGroup } from "../../features/pages/order-food-online/components/button-group";
import { useGetters, useSetters } from "../../features/pages/order-food-online/model";

export const SelectTheNumberOfPeople = ({ setOsob }) => {
  const { t } = useTranslation()
  const getters = useGetters()
  const setters = useSetters()
  const handleProgramPersonCount = (count) => {
    const person_count = count
    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        event: 'calculator_person_count',
        pageURL: `${window.location.pathname}/person_count=${person_count}`,
        person_count
      })
  }
  const oneOsob = () => {
    setters.setOrderFoodCustomerMenuForMe()
    setOsob('1 osoba')
    handleProgramPersonCount('1 osoba')
  }
  const twoOsob = () => {
    setters.setOrderFoodCustomerMenu2People()
    setOsob('2 osoby')
    handleProgramPersonCount('2 osoby')
  }
  const threeOsob = () => {
    setters.setOrderFoodCustomerMenu3People()
    setOsob('3 osoby')
    handleProgramPersonCount('3 osoby')

  }
  const fiveOsob = () => {
    setters.setOrderFoodCustomerMenu5People()
    setOsob('>5')
    handleProgramPersonCount('>5')
  }

  return (
    <div className={styles.selectBlock}>
      <div className={styles.hoverBlock}>
        <ButtonGroup>
          <div className={styles.buttonBlock}>
            <IconButton
              fullWidth
              onClick={oneOsob}
            >
              <ButtonForMe>
                {t('say_goodbye_to_unhealthy_eating.forMe')}
              </ButtonForMe>
            </IconButton>
          </div>
          <div className={styles.buttonBlock}>
            <IconButton
              fullWidth
              onClick={twoOsob}
            >
              <Button2People>
                {t('say_goodbye_to_unhealthy_eating.people2')}
              </Button2People>
            </IconButton>
          </div>
          <div className={styles.buttonBlock}>
            <IconButton
              fullWidth
              onClick={threeOsob}
            >
              <Button3People>
                {t('say_goodbye_to_unhealthy_eating.people3')}
              </Button3People>
            </IconButton>
          </div>
          <div className={styles.buttonBlock}>
            <IconButton
              fullWidth
              onClick={fiveOsob}
            >
              <Button5People>
                {t('say_goodbye_to_unhealthy_eating.people5')}
              </Button5People>
            </IconButton>
          </div>
        </ButtonGroup>
        <div className={styles.hoverInfo}>
          {t('say_goodbye_to_unhealthy_eating.payLess')}
        </div>
      </div>
    </div>
  )
}
const ButtonForMe = props => {
  const { children } = props
  const getters = useGetters()

  return (
    <div
      className={cx(styles.button, {
        [styles.active]: getters.getOrderFoodNumberOfPeople == 1,
      })}
    >
      <p>{children}</p>
    </div>
  )
}

const Button2People = props => {
  const { children } = props
  const getters = useGetters()

  return (
    <div
      className={cx(styles.button, {
        [styles.active]: getters.getOrderFoodNumberOfPeople == 2,
      })}
    >
      <p>{children}</p>
    </div>
  )
}

const Button3People = props => {
  const { children } = props
  const getters = useGetters()

  return (
    <div
      className={cx(styles.button, {
        [styles.active]: getters.getOrderFoodNumberOfPeople == 3,
      })}
    >
      <p>{children}</p>
    </div>
  )
}

const Button5People = props => {
  const { children } = props
  const getters = useGetters()

  return (
    <div
      className={cx(styles.button, {
        [styles.active]: getters.getOrderFoodNumberOfPeople == 5,
      })}
    >
      <p>{children}</p>
    </div>
  )
}