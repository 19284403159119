import React from "react"
import styles from "./dropdown-option.module.css"

export const DropdownOption = props => {
  const { children } = props

  return (
    <button {...props} type="button" className={styles.option}>
      {children}
    </button>
  )
}
