import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { Checkbox as CheckboxView } from '../form/checkbox'

export const Checkbox = ({ name, disabled, children, ...props }) => {
  const { register, control } = useFormContext()
  const fieldValues = register(name, props)

  return (
    <Controller
      name={fieldValues.name}
      defaultValue=""
      render={({ field }) => {
        return (
          <CheckboxView {...field} checked={field.value} disabled={disabled}>
            {children}
          </CheckboxView>
        )
      }}
      control={control}
    />
  )
}
