import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import styles from './food-filter-menu.module.css'
import { IconButton } from '../icon-button'
import { ButtonGroup } from '../../features/pages/order-food-online/components/button-group'

import { FishIcon } from '../vege-menu/images/icons'
import MeatIcon from './images/meat.png'
import VegeIcon from './images/vege.png'
import RybIcon from './images/ryb.png'
import ActiveRybIcon from './images/activeRyb.png'

import {
  useGetters,
  useSetters,
} from '../../features/pages/order-food-online/model'
import Slider from 'react-slick'

export const FoodFilterMenu = ({ setIsChanged }) => {
  const { t } = useTranslation()

  const setters = useSetters()
  const getters = useGetters()
  const [activeButtonId, setActiveButtonId] = useState(1)

  const handleMeatClick = () => {
    if (!getters.isOrderFoodCustomerMenuMeat) {
      setters.setOrderFoodCustomerMenuMeat()
      setIsChanged(true)
    }
  }

  const handleVegeClick = () => {
    if (!getters.isOrderFoodCustomerMenuVege) {
      setters.setOrderFoodCustomerMenuVege()
      setIsChanged(true)
    }
  }

  const handleBezRibClick = () => {
    if (!getters.isOrderFoodCustomerMenuBezRib) {
      setters.setOrderFoodCustomerMenuFish()
      setIsChanged(true)
    }
  }

  const handleButtonClick = (id) => {
    setActiveButtonId(id)
    setIsChanged(true)
  }
  const settings = {
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 3,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 2.5,
          dots: false,
          arrows: false,
        },
      },
    ],
  }

  const buttonSlides = [
    {
      id: 1,
      title: 'Menu s masem',
      img: <img src={MeatIcon} />,
      ischecked: getters.isOrderFoodCustomerMenuMeat,
      clickHandler: handleMeatClick,
    },
    {
      id: 2,
      title: t('home.orderFoodOnline.customerMenuVege'),
      img: <img src={VegeIcon} />,
      ischecked: getters.isOrderFoodCustomerMenuVege,
      clickHandler: handleVegeClick,
    },
    {
      id: 3,
      title: 'Menu bez ryb',
      img: <img src={RybIcon} />,
      img1: <img src={ActiveRybIcon} />,
      ischecked: getters.isOrderFoodCustomerMenuFish,
      clickHandler: handleBezRibClick,
    },
  ]

  const slides = buttonSlides.map((item, index) => (
    <div key={index} className={styles.sliderButtonBlockEl}>
      <div
        className={cx(styles.mobileButtonEl, {
          [styles.activeMobile]: item.id === activeButtonId,
        })}
        onClick={() => handleButtonClick(item.id)}
      >
        {item.id === 3 && <span>{item.id === activeButtonId ? item.img1 : item.img}</span>}
        {item.id !== 3 && <span>{item.img}</span>}
        <p>{item.title}</p>
      </div>
    </div>
  ))

  return (
    <div className={styles.selectBlockContainer}>
      <div className={cx(styles.buttonGroupEl, styles.buttonGroupFilter)}>
        <ButtonGroup>
          <div className={styles.buttonBlock}>
            <IconButton fullWidth onClick={handleMeatClick}>
              <ButtonMeat>
                {t('home.orderFoodOnline.customerMenuMeat')}
              </ButtonMeat>
            </IconButton>
          </div>
          <div className={styles.buttonBlock}>
            <IconButton fullWidth onClick={handleVegeClick}>
              <ButtonVege>
                {t('home.orderFoodOnline.customerMenuVege')}
              </ButtonVege>
            </IconButton>
          </div>
          <div className={styles.buttonBlock}>
            <IconButton fullWidth onClick={handleBezRibClick}>
              <ButtonFish>
                {t('home.orderFoodOnline.customerMenuBezRib')}
              </ButtonFish>
            </IconButton>
          </div>
        </ButtonGroup>
      </div>
      <div className={styles.calendarWrapSlider}>
        <Slider {...settings}>{slides}</Slider>
      </div>
    </div>
  )
}

const ButtonMeat = (props) => {
  const { children } = props
  const getters = useGetters()

  return (
    <div
      className={cx(styles.button, {
        [styles.active]: getters.isOrderFoodCustomerMenuMeat,
      })}
    >
      <span>
        <img src={MeatIcon} />
      </span>
      <p>{children}</p>
    </div>
  )
}

const ButtonVege = (props) => {
  const { children } = props
  const getters = useGetters()

  return (
    <div
      className={cx(styles.button, {
        [styles.active]: getters.isOrderFoodCustomerMenuVege,
      })}
    >
      <span>
        <img src={VegeIcon} />
      </span>
      <p>{children}</p>
    </div>
  )
}

const ButtonFish = (props) => {
  const { children } = props
  const getters = useGetters()

  return (
    <div
      className={cx(styles.button, {
        [styles.active]: getters.isOrderFoodCustomerMenuFish,
      })}
    >
      {getters.isOrderFoodCustomerMenuFish ? (
        <span>
          <img src={ActiveRybIcon} />
        </span>
      ) : (
        <span>
          <img src={RybIcon} />
        </span>
      )}
      <p>{children}</p>
    </div>
  )
}
