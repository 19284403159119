import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { PhoneInput as PhoneInputView } from '../form'

export const PhoneInput = ({ name, ...props }) => {
  const { register, control } = useFormContext()
  const fieldValues = register(name, props)

  return (
    <Controller
      name={fieldValues.name}
      defaultValue=""
      render={({ field }) => <PhoneInputView {...field} error={props.error} />}
      control={control}
    />
  )
}
