import { graphql, useStaticQuery } from 'gatsby'
import React, { useContext } from 'react'
import { Loader } from '../components/loader'
import { RedirectModal } from '../components/redirect-modal'
import { SaleFormModal } from '../components/sale-form-modal'
import { SaleFormModal2 } from '../components/sale-form-modal2'
import SEO from '../components/seo'
import { AffiliateContext } from '../contexts/AffiliateContext'
import FoodCalculatorFilter from "../components/food-calculator-filter";
import {Hero3Red} from "../components/hero3-red";


const PageLayout = ({ site }) => {
  const affiliateContext = useContext(AffiliateContext)
  const { isAffiliate } = affiliateContext.affiliate

  return (
    <>
      <SEO title="Home" />
      {!isAffiliate && <SaleFormModal formName="sale-form" />}
      <RedirectModal />
      <Hero3Red/>
      <SaleFormModal2 formName="Akce 2 dny" />
      <FoodCalculatorFilter/>
    </>
  )
}

const SayGoodbyeToUnhealthyEating = ({ isAffiliate }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            menuLinks {
              name
              link
              section
            }
          }
        }
      }
    `
  )

  return (
    <>
      {site ? <PageLayout site={site} isAffiliate={isAffiliate} /> : <Loader />}
    </>
  )
}

export default SayGoodbyeToUnhealthyEating

