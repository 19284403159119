import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useLocalStorage } from '../../../../../shared/lib/local-storage'
import { clientApi } from '../../../../../shared/api'
import { PromoCodeInput } from '../../../../form/promo-code-input'
import {
  NameErrorRequiredMessage,
  PhoneErrorMessage,
  Terms2ErrorRequiredMessage,
  TermsErrorRequiredMessage,
} from '../../../../../components/forms/forms-error-messages'
import { Button2 } from '../../../../../components/button2'
import { Form, InfoText, Label, FieldGroup } from '../../components/form'
import { Input, PhoneInput, Checkbox } from '../../components/hooks'
import { ModalFormTemplate } from '../../components/templates/modal-form-template'
import {
  OrderStorageType,
  useGetters,
  useOrderFoodContext,
  useSetters,
} from '../../model'

import { OrderFoodOnlineSidebarCalculationBlock } from './order-food-online-sidebar-calculation-block'
import {
  Divider,
  OrderFoodOnlineSidebarCalculationKcalItem,
  OrderFoodOnlineSidebarCalculationPriceItem,
  OrderFoodOnlineSidebarCalculationTransportPerDayItem,
  OrderFoodOnlineSidebarCalculationTransportTotalItem,
  OrderFoodOnlineSidebarCalculationTotalBlockItem,
  OrderFoodOnlineSidebarCalculationPromoCodeItem,
  returunDays,
} from './order-food-online-sidebar-calculation-item'
import styles from './order-food-call-me-modal.module.css'
import { AffiliateContext } from '../../../../../contexts/AffiliateContext'
import { OrderFoodOnlineType } from '../../model/order-food-online-type'
import { getCookie, getReferrer, getUTM } from '../../../../../helpers'
import { OrderFoodOnlineSidebarDPHPriceItemPromo } from './new-order-food-program-modal'

const OrderFoodCallMeModalView = props => {
  const isMobile = window.innerWidth < 1024
  const { form, onSubmit } = props

  const { t } = useTranslation()

  const getters = useGetters()
  const setters = useSetters()

  const [, setState] = useOrderFoodContext()

  const handlePromo = data => {
    setState({
      [OrderFoodOnlineType.PromoCode]: data.value,
      [OrderFoodOnlineType.PriceDiscount]: data.totalPrice,
      [OrderFoodOnlineType.Discount]: data.discount,
    })
  }
  const isGoodbyeUnhealthyForm =
    localStorage.getItem('formName') === 'say-goobay-unhealthy-Eating'

  let dataFromLocalStorage = JSON.parse(
    localStorage.getItem('stopEatHealthyFormData')
  )

  return (
    <ModalFormTemplate
      {...props}
      isGoodbyeUnhealthyForm={isGoodbyeUnhealthyForm}
      title={<h2>{t('home.orderFoodOnline.callMe')}</h2>}
    >
      <div className={styles.block}>
        <div
          className={styles.grid}
          style={{ paddingBottom: isMobile ? 0 : '24px' }}
        >
          {/* {isMobile && isGoodbyeUnhealthyForm && <GenderBlock />} */}

          {/* {isMobile ? <OrderFoodOnlineSidebarProgramBlock /> : ''} */}
          <div className={styles.column}>
            <Form onSubmit={onSubmit}>
              <FieldGroup>
                <Label required>{t('forms.onlineOrderFormNamesLabel')}</Label>
                <Input
                  placeholder={t('forms.onlineOrderFormNamesLabel')}
                  name="name"
                  error={form.errors?.name?.message}
                />
              </FieldGroup>
              <FieldGroup>
                <Label required>Telefon</Label>
                <PhoneInput
                  placeholder="Telefon"
                  name="phone"
                  value="+420"
                  error={form.errors?.phone?.message}
                />
              </FieldGroup>

              {!isGoodbyeUnhealthyForm && (
                <FieldGroup>
                  <Label>{t('forms.onlineOrderFormPromoCodeLabel2')}</Label>
                  <PromoCodeInput
                    placeholder="Promokód"
                    totalPrice={getters.getOrderFoodPriceByDays}
                    onChange={handlePromo}
                    isPromoApplyed={!!getters.getOrderFoodPromoCode}
                  />
                </FieldGroup>
              )}

              {isMobile ? (
                <OrderFoodOnlineSidebarCalculationBlock>
                  {!isGoodbyeUnhealthyForm && (
                    <OrderFoodOnlineSidebarCalculationPriceItem size="sm" />
                  )}
                  {!isGoodbyeUnhealthyForm && <Divider />}
                  {!isGoodbyeUnhealthyForm && (
                    <OrderFoodOnlineSidebarCalculationKcalItem size="sm" />
                  )}{' '}
                  {getters.isOrderFoodDeliveryFree ? null : (
                    <>
                      <Divider />
                      <OrderFoodOnlineSidebarCalculationTransportPerDayItem size="sm" />
                      <Divider />
                      <OrderFoodOnlineSidebarCalculationTransportTotalItem size="sm" />
                    </>
                  )}
                  {!!getters.getOrderFoodDiscount && (
                    <>
                      <Divider />
                      <OrderFoodOnlineSidebarCalculationPromoCodeItem size="sm">
                        {getters.getOrderFoodDiscount &&
                          `-${numberFormat(getters.getOrderFoodDiscount)}`}
                      </OrderFoodOnlineSidebarCalculationPromoCodeItem>
                    </>
                  )}
                  {/* <Divider /> */}
                  <OrderFoodOnlineSidebarCalculationTotalBlockItem size="lg">
                    {isGoodbyeUnhealthyForm
                      ? dataFromLocalStorage.totalPrice
                      : numberFormat(getters.getOrderFoodTotalPrice)}
                  </OrderFoodOnlineSidebarCalculationTotalBlockItem>
                </OrderFoodOnlineSidebarCalculationBlock>
              ) : (
                ''
              )}
              <FieldGroup>
                <Checkbox name="terms1">
                  {t('forms.mainFormCheckTerms1Label')}{' '}
                  <a href="/terms" target="_blank">
                    {t('forms.mainFormCheckTerms2Label')}
                  </a>
                  .
                </Checkbox>
                <Checkbox name="terms2">
                  {t('forms.mainFormCheckTerms3Label')}{' '}
                  <a href="/gdpr" target="_blank">
                    {t('forms.mainFormCheckTerms4Label')}
                  </a>
                </Checkbox>
              </FieldGroup>

              {!isGoodbyeUnhealthyForm && (
                <InfoText>* {t('forms.mainFormCTAdesc')}</InfoText>
              )}

              <div style={{ paddingBottom: isMobile ? '24px' : 0 }}>
                <Button2
                  color="secondary"
                  size="sm"
                  fullWidth
                  buttonType="submit"
                  disabled={form.isSubmitting || !form.isDirty}
                  style={
                    isGoodbyeUnhealthyForm ? { background: '#F22F2F' } : {}
                  }
                >
                  {t('home.orderFoodOnline.callMe')}
                </Button2>
              </div>
            </Form>
          </div>
          {!isGoodbyeUnhealthyForm && (
            <div className={styles.column}>
              {/* {!isMobile ? <OrderFoodOnlineSidebarProgramBlock /> : ''} */}
              {/* {!isMobile && isGoodbyeUnhealthyForm && <GenderBlock />} */}
              {!isMobile ? (
                <OrderFoodOnlineSidebarCalculationBlock>
                  <OrderFoodOnlineSidebarCalculationPriceItem size="sm" />

                  <Divider />

                  <OrderFoodOnlineSidebarCalculationKcalItem size="sm" />

                  {getters.isOrderFoodDeliveryFree ? null : (
                    <>
                      <Divider />
                      <OrderFoodOnlineSidebarCalculationTransportPerDayItem size="sm" />
                      <Divider />
                      <OrderFoodOnlineSidebarCalculationTransportTotalItem size="sm" />
                    </>
                  )}
                  {!!getters.getOrderFoodDiscount && (
                    <>
                      <Divider />
                      <OrderFoodOnlineSidebarCalculationPromoCodeItem size="sm">
                        {getters.getOrderFoodDiscount &&
                          `-${numberFormat(getters.getOrderFoodDiscount)}`}
                      </OrderFoodOnlineSidebarCalculationPromoCodeItem>
                    </>
                  )}
                  <Divider />
                  <OrderFoodOnlineSidebarCalculationTotalBlockItem size="lg">
                    {numberFormat(getters.getOrderFoodTotalPrice)}
                  </OrderFoodOnlineSidebarCalculationTotalBlockItem>
                </OrderFoodOnlineSidebarCalculationBlock>
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      </div>
    </ModalFormTemplate>
  )
}

const initialState = () => ({
  name: undefined,
  phone: '+420',
  terms1: false,
  terms2: false,
  promo: '',
})

const schema = yup
  .object({
    name: yup.string(<NameErrorRequiredMessage />).required(),
    phone: yup
      .string()
      .min(9, <PhoneErrorMessage />)
      .phone(),
    terms1: yup.bool().oneOf([true], <TermsErrorRequiredMessage />),
    terms2: yup.bool().oneOf([true], <Terms2ErrorRequiredMessage />),
  })
  .required()

export const OrderFoodCallMeModal = props => {
  const getters = useGetters()

  const [, setOrderStorage] = useLocalStorage(
    OrderStorageType.orderFoodOnline,
    {}
  )
  const isGoodbyeUnhealthyForm =
    localStorage.getItem('formName') === 'say-goobay-unhealthy-Eating'

  const affiliateContext = useContext(AffiliateContext)

  const methods = useForm({
    defaultValues: initialState(),
    resolver: yupResolver(schema),
  })

  const { affiliateId } = affiliateContext?.affiliate || {}
  const handleSubmit = async data => {
    try {
      if (!isGoodbyeUnhealthyForm) {
        const isEn = document.location.pathname.includes('/en')
        const testovani = localStorage.getItem('website-version')

        const obj = {
          ...data,
          price: getters.getOrderFoodPriceByDays,
          promo: getters.getOrderFoodPromoCode || data.promo,
          person: getters.getOrderFoodNumberOfPeople,
          program: getters.getOrderFoodProgram,
          lenght: getters.getOrderProgramDuration,
          menu: getters.isOrderFoodCustomerMenuVege ? 'Vegetarian' : 'Normal',
          sex: getters.getOrderFoodCustomerGender,
          weekLenght: getters.getOrderFoodWeekMealLength,
          kalorie: getters.getOrderNumberOfKJ,
          checkTerms: data.terms1,
          checkTerms2: data.terms2,
          numberOfMeals: getters.getOrderFoodNumberOfMeals,
          affiliateId,
          testovani,
          price_daily: getters.getOrderFoodPrice,
        }
        localStorage.setItem('conversation', JSON.stringify(obj))
        localStorage.setItem('FormName', 'order-call')

        const req = await clientApi.application({
          ...data,
          price: getters.getOrderFoodTotalPrice,
          promo: getters.getOrderFoodPromoCode || data.promo,
          checkTerms: data.terms1,
          checkTerms2: data.terms2,
          numberOfMeals: getters.getOrderFoodNumberOfMeals,
          affiliateId,
          testovani,
          form_name:'callback',
        })

        if (req) {
          const response = req

          setOrderStorage({})

          setTimeout(() => {
            window.dataLayer.push({
              event: 'ga.pageview',
              pageURL: isEn ? '/en/thank-you' : '/thank-you',
              pageType: 'Purchase',
              testovani: 'new online order form',
            })
            props?.hideModal && props.hideModal()
          }, 300)

          if (affiliateId) {
            const getParamsData = Object.entries(response)
              .map(([key, value]) => `${key}=${value || 0}`)
              .reduce(
                (previousValue, currentValue) =>
                  `${previousValue}&${currentValue}`
              )
            console.log(
              'affiliate TY: meals + getParamsData',
              data.mealsPerDay,
              getParamsData
            )

            window.location.href = isEn
              ? `/en/thank-you-affiliate/?${getParamsData}&actionTrackerId=430565`
              : `/thank-you-affiliate/?${getParamsData}&actionTrackerId=430565`
          } else {
            console.log('no affiliateId')
            window.location.href = isEn
              ? '/en/order-food-online/thank-you-order-received/'
              : '/order-food-online/thank-you-order-received/'
          }
        }
      } else {
        const UTMS = getUTM()
        let referrer = getReferrer()
        let dataFromLocalStorage = JSON.parse(
          localStorage.getItem('stopEatHealthyFormData')
        )

        console.log('UTMS: ', UTMS, 'referrer', referrer)

        console.log(
          'referrer directly from LC',
          JSON.parse(localStorage.getItem('referrer'))
        )

        let getPrice = document.querySelector('[name="price"]').value

        const isEn = document.location.pathname.includes('/en')
        const testovani = localStorage.getItem('website-version')
        const totalPricePerDays =
          isGoodbyeUnhealthyForm &&
          returunDays(dataFromLocalStorage.program) *
            dataFromLocalStorage.totalPrice

        let dataObj = {
          form_name: isEn ? 'kalkulacka_en' : 'kalkulacka',
          language: isEn ? 'en' : 'cs',
          region: process.env.GATSBY_COUNTRY,
          branchId: process.env.GATSBY_BRANCH_ID,
          phone: data.phone,
          promo: getters.getOrderFoodPromoCode
            ? getters.getOrderFoodPromoCode
            : '',
          plan: dataFromLocalStorage.plan,
          program: dataFromLocalStorage.program,
          menu: dataFromLocalStorage.menu,
          osob: dataFromLocalStorage.osob,
          checkTerms: data.terms1,
          checkTerms2: data.terms2,
          price: dataFromLocalStorage.totalPrice,
          cjevent: affiliateId,
          utm_source: UTMS.UTM_SOURCE,
          utm_medium: UTMS.UTM_MEDIUM,
          utm_campaign: UTMS.UTM_CAMPAIGN,
          utm_term: UTMS.UTM_TERM,
          utm_content: UTMS.UTM_CONTENT,
          totalPrice: totalPricePerDays,
          referrer: referrer,
          roistat: getCookie('roistat_visit'),
          ga: getCookie('_ga'),
          testovani: testovani,
        }
        localStorage.setItem('FormName', dataObj.form_name)
        localStorage.setItem('Calculation-data', JSON.stringify(dataObj))
        const req = await fetch(
          `${process.env.GATSBY_APP_API_URL}/application`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(dataObj),
          }
        )
        if (req.ok) {
          const response = await req.json()
          // const isAffiliate = true
          // if (isAffiliate) {
          //   const getParamsData = Object.entries(response)
          //     .map(([key, value]) => `${key}=${value || 0}`)
          //     .reduce(
          //       (previousValue, currentValue) =>
          //         `${previousValue}&${currentValue}`
          //     );
          //   // console.log("meals + getParamsData", data.mealsPerDay, getParamsData);
          //   window.location.href = isEn
          //     ? `/en/thank-you-affiliate/?${getParamsData}&actionTrackerId=430565`
          //     : `/thank-you-affiliate/?${getParamsData}&actionTrackerId=430565`;
          // } else {
          //   window.location.href = isEn ? "/en/thank-you" : "/thank-you";
          // }
          setTimeout(() => {
            window.dataLayer.push({
              event: 'ga.pageview',
              pageURL: isEn ? '/en/thank-you' : '/thank-you',
              pageType: 'Purchase',
              testovani: testovani,
            })
          }, 300)
          window.location.href = isEn ? '/en/thank-you' : '/thank-you'
        }
      }
    } catch (err) {
      alert('Something went wrong, please try again!')
    }
  }

  return (
    <FormProvider {...methods}>
      <OrderFoodCallMeModalView
        {...props}
        form={methods.formState}
        onSubmit={methods.handleSubmit(handleSubmit)}
      />
    </FormProvider>
  )
}

const numberFormat = value => {
  if (!value) return value
  return (value || '')?.toLocaleString().replace(',', ' ')
}
