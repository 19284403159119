import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { Container } from '../container'
import { useTranslation } from 'react-i18next'
import styles from './food-calculator.module.css'
import { FoodFilterMenu } from '../food-filter-menu/food-filter-menu'
import { ChooseAProgram } from '../chooseAProgram'
import { DurationOfTheProgram } from '../durationOfTheProgram'
import { useGetters, } from '../../features/pages/order-food-online/model'
import { WeightLossProgram } from '../weightLossProgram'
import CloseIcon from './images/closeIconCalc.png'
const FoodCalculatorFilter = ({ id, price, setPrice, oldPrice, setOldPrice, plan, setPlan }) => {
  const getters = useGetters()

  const { t } = useTranslation()
  const [isChanged, setIsChanged] = useState(false)
  const [menu, setMenu] = useState('5chodové menu')
  const [osob, setOsob] = useState('1 osoba')
  const [plan2disabled, setPlan2Disabled] = useState(false)
  const [plan3disabled, setPlan3Disabled] = useState(false)
  const [menu2xDisabled, setMenu2xDisabled] = useState(false)
  const [menu3xDisabled, setMenu3xDisabled] = useState(false)
  const [menu4xDisabled, setMenu4xDisabled] = useState(false)
  const [program, setProgram] = useState('Měsíc')

  useEffect(() => {
    if (isChanged) {
      setIsChanged(true)
    }
  }, [isChanged])

  // const getPrice = (menu, plan, osob, program = 'Měsíc') => {
  //   let price = null

  //   if (program === 'Měsíc') {
  //     if (plan === 'Zhubnout') {
  //       if (menu === '5chodové menu') {
  //         price = 559
  //       }
  //       if (menu === '4chodové menu') {
  //         price = 503
  //       }
  //       if (menu === '3chodové menu') {
  //         price = 419
  //       }
  //       if (menu === '2chodové menu') {
  //         price = 339
  //       }
  //     }
  //     if (plan === 'Nabírat') {
  //       if (menu === '5chodové menu') {
  //         price = 599
  //       }
  //     }
  //     if (plan === 'Udržovat') {
  //       if (menu === '5chodové menu') {
  //         price = 579
  //       }
  //       if (menu === '4chodové menu') {
  //         price = 521
  //       }
  //       if (menu === '3chodové menu') {
  //         price = 439
  //       }
  //     }
  //   }

  //   if (getters.isOrderFoodNumberOfPeopleOne) {
  //     setOldPrice(null)
  //   }

  //   if (getters.isOrderFoodNumberOfPeopleTwo) {
  //     setOldPrice(price)
  //     price = (price - price * (10 / 100)).toFixed()
  //   }

  //   if (getters.isOrderFoodNumberOfPeopleThreeOrMore) {
  //     setOldPrice(price)
  //     price = (price - price * (15 / 100)).toFixed()
  //   }

  //   if (getters.isOrderFoodNumberOfPeopleCompany) {
  //     setOldPrice(price)
  //     price = (price - price * (20 / 100)).toFixed()
  //   }
  //   if (osob === '1 osoba') {
  //     setOldPrice(null)
  //   }

  //   if (osob === '2 osoby') {
  //     setOldPrice(price)
  //     price = (price - price * (10 / 100)).toFixed()
  //   }

  //   if (osob === '3 osoby') {
  //     setOldPrice(price)
  //     price = (price - price * (15 / 100)).toFixed()
  //   }

  //   if (osob === '>5') {
  //     setOldPrice(price)
  //     price = (price - price * (20 / 100)).toFixed()
  //   }
  //   return price
  // }
  const getPrice = (menu, plan, osob, program) => {
    let price = null
    if (program === '2 týdny') {
      switch (plan) {
        case 'Zhubnout':
          if (menu === '5chodové menu') {
            price = 608
          }
          if (menu === '4chodové menu') {
            price = 547
          }
          if (menu === '3chodové menu') {
            price = 461
          }
          if (menu === '2chodové menu') {
            price = 377
          }
          break
        case 'Udržovat':
          if (menu === '5chodové menu') {
            price = 629
          }
          if (menu === '4chodové menu') {
            price = 566
          }
          if (menu === '3chodové menu') {
            price = 482
          }
          // if (menu === "2chodové menu") {
          //   price = 420
          // }
          break
        case 'Nabírat':
          if (menu === '5chodové menu') {
            price = 649
          }
          // if (menu === "3chodové menu") {
          //   price = 420
          // }
          // if (menu === "2chodové menu") {
          //   price = 420
          // }
          break
      }
    }

    if (program === 'Měsíc') {
      switch (plan) {
        case 'Zhubnout':
          if (menu === '5chodové menu') {
            price = 587
          }
          if (menu === '4chodové menu') {
            price = 528
          }
          if (menu === '3chodové menu') {
            price = 439
          }
          if (menu === '2chodové menu') {
            price = 356
          }
          break
        case 'Udržovat':
          if (menu === '5chodové menu') {
            price = 608
          }
          if (menu === '4chodové menu') {
            price = 547
          }
          if (menu === '3chodové menu') {
            price = 461
          }
          // if (menu === "2chodové menu") {
          //   price = 384
          // }
          break
        case 'Nabírat':
          if (menu === '5chodové menu') {
            price = 629
          }
          // if (menu === "3chodové menu") {
          //   price = 390
          // }
          // if (menu === "2chodové menu") {
          //   price = 420
          // }
          break
      }
    }

    if (program === 'Dva měsíce') {
      switch (plan) {
        case 'Zhubnout':
          if (menu === '5chodové menu') {
            price = 566
          }
          if (menu === '4chodové menu') {
            price = 509
          }
          if (menu === '3chodové menu') {
            price = 419
          }
          if (menu === '2chodové menu') {
            price = 339
          }
          break
        case 'Udržovat':
          if (menu === '5chodové menu') {
            price = 587
          }
          if (menu === '4chodové menu') {
            price = 528
          }
          if (menu === '3chodové menu') {
            price = 440
          }
          // if (menu === "2chodové menu") {
          //   price = 420
          // }
          break
        case 'Nabírat':
          if (menu === '5chodové menu') {
            price = 608
          }
          // if (menu === "3chodové menu") {
          //   price = 370
          // }
          // if (menu === "2chodové menu") {
          //   price = 420
          // }
          break
      }
    }

    if (osob === '1 osoba') {
      setOldPrice(null)
    }

    if (osob === '2 osoby') {
      setOldPrice(price)
      price = (price - price * (10 / 100)).toFixed()
    }

    if (osob === '3 osoby') {
      setOldPrice(price)
      price = (price - price * (15 / 100)).toFixed()
    }

    if (osob === '>5') {
      setOldPrice(price)
      price = (price - price * (20 / 100)).toFixed()
    }

    return price
  }
  useEffect(() => {
    const priceValue = getPrice(menu, plan, osob, program)
    setPrice(priceValue)
    const dataToStore = {
      menu,
      plan,
      osob,
      program,
      totalPrice: priceValue,
    }

    localStorage.setItem('stopEatHealthyFormData', JSON.stringify(dataToStore))
    const priceMobileObj = {
      priceValue,
      oldPrice,
      plan,
    }
    const priceMobile = JSON.stringify(priceMobileObj)
    localStorage.setItem('priceMobile', priceMobile)
    if (plan === 'Udržovat') {
      setMenu2xDisabled(true)
    } else {
      setMenu2xDisabled(false)
    }
    if (plan === 'Nabírat') {
      setMenu2xDisabled(true)
      setMenu4xDisabled(true)
      setMenu3xDisabled(true)
    } else {
      setMenu4xDisabled(false)
      setMenu3xDisabled(false)
    }
    if (menu === '2chodové menu') {
      setPlan2Disabled(true)
      setPlan3Disabled(true)
    } else if (
      menu === '3chodové menu' ||
      (menu === '4chodové menu' && plan === 'Nabírat')
    ) {
      setPlan3Disabled(true)
    } else {
      setPlan2Disabled(false)
      setPlan3Disabled(false)
    }
  }, [plan, menu, osob, program])

  const getBack = () => {
    const isEn = document.location.pathname.includes('/en')
    window.location.href = isEn ? '/en' : '/'
  }

  const programmName = getters.isCheckedButtonBasic
    ? 'lunchAndDinnerProgram'
    : getters.isCheckedButtonMaintenance
    ? 'maintenanceProgram'
    : getters.isCheckedButtonPickling
    ? 'weightGainProgram'
    : 'weightLossProgram'

  return (
    <section className={styles.section} id={id}>
      <Container isWide={true}>
        <h3
          className={cx(styles.sectionTitle, 'fancyUnderlineText text-center')}
        >
          <span>{t('sayGoodbyeToUnhealthyEating.greenTitle')}</span>
          {t('sayGoodbyeToUnhealthyEating.title')}
        </h3>
        <p className={cx(styles.sectionSubtitle, 'text-center')}>
          {t('sayGoodbyeToUnhealthyEating.preTitle')}
        </p>
      </Container>
      <div className={styles.iconClose} onClick={getBack}>
        <img src={CloseIcon} alt="close" />
        <span>{t('sayGoodbyeToUnhealthyEating.close')}</span>
      </div>
      <div className={cx(styles.mainFilterWrapper)}>
        <div className={styles.wrapContent}>
          <div className={cx(styles.filterwrap)}>
            <h4 className={cx(styles.filterTitle)}>
              {t('sayGoodbyeToUnhealthyEating.chooseAMenu')}
            </h4>
            <FoodFilterMenu setIsChanged={setIsChanged} />
          </div>
          <div className={cx(styles.filterwrap)}>
            <h4 className={cx(styles.filterTitle)}>
              {t('say_goodbye_to_unhealthy_eating.vyberteProgram')}
            </h4>
            <ChooseAProgram
              menu2xDisabled={menu2xDisabled}
              menu3xDisabled={menu3xDisabled}
              menu4xDisabled={menu4xDisabled}
              plan2disabled={plan2disabled}
              plan3disabled={plan3disabled}
              setIsChanged={setIsChanged}
              setPlan={setPlan}
            />
          </div>
          <div className={cx(styles.menuTextBox)}>
            <h3>{t(`home.orderFoodOnline.${programmName}`)}</h3>
            <p>{t(`home.orderFoodOnline.programModal.${programmName}`)}</p>
          </div>
          <div>
            <DurationOfTheProgram
              setIsChanged={setIsChanged}
              setMenu={setMenu}
              setOsob={setOsob}
              program={program}
              setProgram={setProgram}
              menu2xDisabled={menu2xDisabled}
              menu3xDisabled={menu3xDisabled}
              menu4xDisabled={menu4xDisabled}
            />
          </div>
          <div>
            {/* <WeWillShipThisWeek setIsChanged={setIsChanged} menu={menu} /> */}
          </div>
        </div>
        <ProgramNameFactory
          price={price}
          oldPrice={oldPrice}
          program={getters.isCheckedButtonSlimming}
          plan={plan}
          menu={menu}
          osob={osob}
        />
      </div>
    </section>
  )
}

const ProgramNameFactory = ({ price, oldPrice, plan, menu, osob }) => {
  const getters = useGetters()

  const checkplan = plan => {
    if (getters.isCheckedButtonBasic) {
      return 'Zakladni'
    }
    if (getters.isCheckedButtonSlimming) {
      return 'Hubnouci'
    } else {
      return plan
    }
  }
  return (
    <WeightLossProgram
      menu={menu}
      price={price}
      oldPrice={oldPrice}
      plan={plan}
      checkedPlan={checkplan(plan)}
      osob={osob}
    />
  )
}
export default FoodCalculatorFilter
