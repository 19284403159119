import React, { useState, useEffect, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './weightLossProgram.module.css'
import meat from './images/meat.svg'
import vege from './images/vege.png'
import ryb from './images/ryb.png'
import eat from './images/circle.png'
import calendar from './calendar.svg'
import mainFormStyles from '../forms/main-form2.module.css'
import { Button2 } from '../button2'
import phone from './images/phone.png'
import car from './images/car.svg'
import { useModalContext } from '../../shared/lib/react-context-modal'
import { NewOrderFoodCallMeModal } from '../../features/pages/order-food-online/features/order-food-online/new-order-food-program-modal'
import { useGetters } from '../../features/pages/order-food-online/model'
import { OrderFoodCallMeModal } from '../../features/pages/order-food-online/features/order-food-online/order-food-call-me-modal'
import { useSmoothScroll } from '../../hooks/useSmoothScroll'

function getDiscountPrice(price, plan) {
  const discount =
    plan && plan === '2 měsíce' ? 5 : plan && plan === '3 měsíce' ? 8 : null
  return (price - price * (discount / 100)).toFixed()
}

function getDiscountPricePerPortion(price, plan) {
  const discountPortion = plan && plan === '2 týdny' ? 5 : plan
  return ((price - price * (discountPortion / 100)).toFixed() / 5).toFixed()
}

export const WeightLossProgram = ({
  price,
  oldPrice,
  plan,
  checkedPlan,
  menu,
  osob,
}) => {
  const { t } = useTranslation()
  const scroll = useSmoothScroll()

  const [discountPrice, setDiscountPrice] = useState(null)
  const [discountPortionPrice, setDiscountPortionPrice] = useState(null)
  const getters = useGetters()
  const isEnURL = window.location.pathname.includes('/en');

  const { showModal } = useModalContext()
  const handleOnlineOrder = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        event: `click_cta_online_order_calc_cta`,
        pageURL: window.location.pathname,
      })

  }

  const handleCallMe = () => {
    showModal(OrderFoodCallMeModal)
    localStorage.setItem('formName', 'say-goobay-unhealthy-Eating')
  }

  useEffect(() => {
    const discountPriceValue = getDiscountPrice(price, plan)
    setDiscountPrice(discountPriceValue)
    const discountPortionPriceValue = getDiscountPricePerPortion(price, plan)
    setDiscountPortionPrice(discountPortionPriceValue)
  }, [price, oldPrice, plan])
  const scrollDeliverySection = () => {
    scroll.animateScroll(document.getElementById('delivery-section'))
  }

  const menuIcon = getters.isOrderFoodCustomerMenuMeat
    ? meat
    : getters.isOrderFoodCustomerMenuVege
      ? vege
      : ryb
  const osobDiscount = (osob) => {
    switch (osob) {
      case '2 osoby':
        return '-10%'
      case '3 osoby':
        return '-15%'
      case '>5':
        return '-20%'
      default:
        return null
    }
  }

  return (
    <div style={{ width: '325px' }} className={styles.mainPriceBox}>
      <div className={styles.positionFix}>
        <div className={styles.priceBoxCalculator}>
          <div className={styles.newCalculatorTitle}>
            <h3>{t(`say_goodbye_to_unhealthy_eating.subTitle.${checkedPlan}`)}</h3>
            <img src={menuIcon} alt="meat" />
          </div>
          <div className={styles.newCalculatorText}>
            <p>
              <img src={eat} alt="eat" />

              <span>
                {menu[0]}
                {menu[0] !== '5'
                  ? t('say_goodbye_to_unhealthy_eating.mealsADay')
                  : t('say_goodbye_to_unhealthy_eating.mealsADayFive')}
              </span>
            </p>
            <p>
              <img src={calendar} alt="calendar" />
              <span>
                {t('say_goodbye_to_unhealthy_eating.daysAWeek')}
                {/* <span className={styles.grayText}>
                  {t('say_goodbye_to_unhealthy_eating.month')}
                </span> */}
              </span>
            </p>
          </div>
          <div className={styles.newCalculatorPrice}>
            {osob !== '1 osoba' && (
              <span className={styles.disountRed}>{osobDiscount(osob)}</span>
            )}
            <div className={styles.displayCalc}>
              <div>
                <h3>{t('say_goodbye_to_unhealthy_eating.pricePerDay')}</h3>
                <p>{t('say_goodbye_to_unhealthy_eating.includingTransport')}</p>
              </div>
              <div className={styles.priceCount}>
                <span>{t('say_goodbye_to_unhealthy_eating.from')}</span>
                <h4>
                  {price ? price : '420'}
                  {t('say_goodbye_to_unhealthy_eating.price')}
                </h4>
              </div>
              <div
                style={{
                  textDecoration: 'line-through',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  position: 'absolute',
                  top: '47px',
                  right: '23px',
                }}
              >
                {oldPrice && `${oldPrice} ${t('czhKrona')}`}
              </div>
            </div>
          </div>
          <div className={styles.callMe}>
            <Button2
              id={styles.firstBtn}
              color="secondary"
              variant="outline"
              size={'sm'}
              buttonType="submit"
              disabled={false}
              className={mainFormStyles.submitButton}
              onClick={handleCallMe}
            >
              {t('forms.mainFormCTA')}
            </Button2>
            <>
              <p
                style={{
                  textAlign: 'center',
                  font: 'normal normal normal 14px/21px Gilroy',
                  letterSpacing: '-0.01px',
                  color: '#87A5C0',
                  opacity: 1,
                  margin: '8px auto',
                }}
              >
                {t('forms.mainFormCTApar')}
              </p>
              <a href={ isEnURL ? '/en/order-food-online' : '/order-food-online'} onClick={handleOnlineOrder}>
                <Button2
                  id={styles.secondBtn}
                  color="secondary"
                  size={'sm'}
                  disabled={false}
                  className={mainFormStyles.submitButton}
                >
                  {t('forms.mainFormCTA2')}{' '}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="10.94"
                    viewBox="0 0 16 10.94"
                  >
                    <path
                      id="icons8-up"
                      d="M18.569,7.219l-.646.661-4.553,4.539a.924.924,0,0,0,1.307,1.307l2.973-2.988V22.286a.919.919,0,1,0,1.839,0V10.738l2.973,2.988a.924.924,0,0,0,1.307-1.307L19.215,7.879Z"
                      transform="translate(23.219 -13.099) rotate(90)"
                      fill="#fff"
                    />
                  </svg>
                </Button2>
              </a>
            </>

            <input type="hidden" name="price" />
          </div>
        </div>
        <div className={styles.phoneInfo}>
          <p>{t('say_goodbye_to_unhealthy_eating.needAdvice')}</p>
          <h3>{t('say_goodbye_to_unhealthy_eating.callUs')}</h3>
          <a href="/">
            <img src={phone} alt="phone" />
            <span> 226 288 200</span>
          </a>
        </div>
        <div className={styles.delivery}>
          <img src={car} alt="car" />
          <p>{t('say_goodbye_to_unhealthy_eating.shippingIsAlways')}</p>
          <a onClick={scrollDeliverySection}>
            {t('say_goodbye_to_unhealthy_eating.free')}
          </a>
        </div>
      </div>
    </div>
  )
}

const numberFormat = (value) => {
  if (!value) return value
  return (value || '')?.toLocaleString().replace(',', ' ')
}
